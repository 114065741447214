<template>
	<AssistantComponent />
</template>

<script>
import { mapGetters } from 'vuex';
import AssistantComponent from '@/components/assistant/Index';
import { isPastGivenTime } from '@/utils/time.js';

export default {
	name: 'OrthographiqAssistant',
	components: { AssistantComponent },
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
		...mapGetters('appState', { selectingFormationId: 'selectingFormationId' }),
	},
	async mounted() {
		const { formationId } = this.$router.currentRoute.params;
		if (this.formationProgress && this.formationProgress.formation._id === formationId) return;
		const formationProgress = this.$store.dispatch('profile/formationProgress', { idFormation: formationId });
		if (formationProgress.error) return;
		this.$store.dispatch('appState/selectFormationId', { formationId: formationId });
	},
	methods: {
		filterFormationBegan(formations) {
			return formations.filter((f) => isPastGivenTime(f.begin_date));
		},
	},
};
</script>
